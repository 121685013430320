<template>
  <v-container grid-list-md fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm7 md5>
        <v-card>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <DropdownTranslate />
              <h1>{{ $t('pages.appSelector.title') }}</h1>
              <p
                v-if="!isConfigValid"
                class="red--text"
              >
                {{ $t('pages.appSelector.invalidAppConfiguration') }}
              </p>
              <p
                v-else-if="Array.isArray(applications) && applications.length"
              >
                {{ $t('pages.appSelector.description') }}
              </p>
              <p
                v-else
              >
                {{ $t('pages.appSelector.noApplication') }}
              </p>
            </v-flex>
          </v-layout>
          <v-layout v-if="isConfigValid" align-start justify-center row fill-height class="pb-4">
            <v-flex
              v-for="app in applications"
              :key="app.id"
              @click="loadApp(app.id)"
              xs9 sm4 md4
              style="cursor: pointer"
              class="text-center">
              <v-card
                class="mx-auto pa-4"
                max-width="344"
                outlined
              >
                <h3>{{ app.name }}</h3>
              </v-card>
            </v-flex>
            <v-flex
              v-if="!Array.isArray(applications) || !applications.length"
              class="text-center"
            >
              <v-btn
                color="primary"
                @click="logout"
              >
                {{ $t('auth.disconnected') }}
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-else align-start justify-center row fill-height class="pb-4">
            <v-flex
              class="text-center"
            >
              <v-btn
                color="primary"
                @click="tryNewConfig()"
              >
                {{ $t('pages.appSelector.retry') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppSelector',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('settings', ['isConfigValid']),
    applications() {
      return this.$store.state.settings.apps;
    },
  },
  methods: {
    async loadApp(appId) {
      try {
        localStorage.currentApplication = appId;
        const selectedApp = this.$store.state.settings.apps
          .find((app) => app.id === appId);
        this.$store.dispatch('settings/FETCH_SETTINGS', {
          customer: selectedApp.customer,
          id: selectedApp.id,
        })
          .then(() => {
            if (this.isConfigValid) {
              this.$store.commit('auth/CLEAR_USER_FFLY4U_PREFS');
              window.location = localStorage.nextPath || '/';
            }
          });
      } catch (error) {
        if (process.env.NODE_ENV === 'production') {
          console.log('app selector config error', this.isConfigValid);
        }
      }
    },
    async logout() {
      await this.$store.dispatch('auth/LOG_OUT');
    },
    tryNewConfig() {
      this.$store.commit('settings/SET_CONFIG_FILE_STATE', true);
    },
  },
  metaInfo() {
    return {
      title: this.$t('pages.appSelector.title'),
    };
  },
};
</script>
